<template>
  <div class="map-container">
    <Map
      v-if="places.length"
      width="100vw"
      height="calc(100vh - 76px)"
      position="absolute"
      :places="places"
    />
    <MapFilter />
  </div>
</template>

<script>
import Map from "@/components/Map";
import MapFilter from "@/components/MapFilter";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  components: { MapFilter, Map },
  computed: {
    ...mapGetters({ places: "map/getPlaces", filters: "map/getFilters" })
  },
  methods: {
    ...mapActions({ fetch_places: "map/fetch_places" })
  },
  mounted() {
    this.fetch_places();
  }
};
</script>

<style scoped>
#map-container {
  width: 100vw;
  height: calc(100vh - 76px);
}
</style>

import Vue from "vue";
import App from "./App.vue";
import $store from "./store";
import router from "./router";

import VCalendar from "v-calendar";
import vSelect from "vue-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFilter,
  faAngleLeft,
  faTrash,
  faPencilAlt,
  faPlus,
  faSave,
  faCheck,
  faFileAlt,
  faCheckCircle,
  faCircle,
  faMapMarkerAlt,
  faGlobeEurope,
  faMapSigns
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "leaflet/dist/leaflet.css";
import "vue-select/dist/vue-select.css";
import "flag-icon-css/css/flag-icon.min.css";
import "leaflet-geosearch/dist/geosearch.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

library.add(faFilter);
library.add(faAngleLeft);
library.add(faTrash);
library.add(faPencilAlt);
library.add(faPlus);
library.add(faSave);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faFileAlt);
library.add(faCircle);
library.add(faMapMarkerAlt);
library.add(faGlobeEurope);
library.add(faMapSigns);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.filter("striphtml", function(value: string) {
  const div = document.createElement("div");
  div.innerHTML = value;
  return div.textContent || div.innerText || "";
});

Vue.use(VCalendar);
Vue.component("v-select", vSelect);

new Vue({
  router,
  store: $store,
  render: h => h(App)
}).$mount("#app");

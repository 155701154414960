import RequestUtils from "./requestUtils";
import { Post } from "../models/Post";

export default class PostUtils extends RequestUtils {
  fetchAllPosts(is_published: number): Promise<Post[]> {
    return this.ax
      .get(`/post?is_published=${is_published}`)
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchPostById(id: number): Promise<Post> {
    return this.ax
      .get(`/post/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  createPost(post: Post): Promise<any> {
    return this.ax
      .post(`/post`, post)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updatePost(post: Post): Promise<any> {
    return this.ax
      .put(`/post/${post.id}`, post)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  deletePost(id: number): Promise<any> {
    return this.ax
      .delete(`/post/${id}`)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  publishComment(id: number, text: string): Promise<Post> {
    return this.ax
      .post(`/post/${id}/comment`, { text })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  deleteComment(post_id: number, id: number): Promise<Post> {
    return this.ax
      .delete(`/post/${post_id}/comment/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }
}

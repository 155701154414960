<template>
  <div class="row gx-5">
    <div class="col-md-4 d-flex mb-3">
      <div class="card card-raised-blue">
        <div class="card-body px-4">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="me-2">
              <div class="display-5">{{ visited_places.length }}</div>
              <div class="card-text">Endroits visités</div>
            </div>
            <div class="icon-circle bg-blue text-white">
              <font-awesome-icon icon="map-marker-alt" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 d-flex mb-3">
      <div class="card card-raised-yellow">
        <div class="card-body px-4">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="me-2">
              <div class="display-5">{{ visited_countries.length }}</div>
              <div class="card-text">Pays différents</div>
            </div>
            <div class="icon-circle bg-yellow text-white">
              <font-awesome-icon icon="globe-europe" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 d-flex mb-3">
      <div class="card card-raised-green">
        <div class="card-body px-4">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="me-2">
              <div class="display-5">
                {{ places.length - visited_places.length }}
              </div>
              <div class="card-text">Endroits qu'il reste à visiter</div>
            </div>
            <div class="icon-circle bg-green text-white">
              <font-awesome-icon icon="map-signs" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TravelDashboard",
  computed: {
    ...mapGetters({ user: "user/getUser", places: "map/getPlaces" }),
    visited_places() {
      return this.places.filter(place => place.done);
    },
    visited_countries() {
      const countries = [];
      this.visited_places.forEach(place => {
        if (!countries.find(c => c.id === place.country.id))
          countries.push(place.country);
      });
      return countries;
    }
  }
};
</script>

<style scoped>
.card {
  text-align: left;
  width: 100%;
}
.card-raised-blue {
  border: none;
  border-left: medium solid var(--blue);
}
.card-raised-yellow {
  border: none;
  border-left: medium solid var(--yellow);
}
.card-raised-green {
  border: none;
  border-left: medium solid var(--green);
}
.bg-blue {
  background-color: var(--blue);
}
.bg-yellow {
  background-color: var(--yellow);
}
.bg-green {
  background-color: var(--green);
}
.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.display-5 {
  line-height: 2rem;
  color: #212121;
  font-size: 1.5rem;
  font-weight: 400;
}
.icon-circle {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-text {
  opacity: 0.6;
  font-size: 0.8rem;
}
</style>

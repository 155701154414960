<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>Paramètres de l'application</h3>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="error">
          <p :key="err.param" v-for="err in error">{{ err.msg }}</p>
        </div>
        <h4>Liste des utilisateurs :</h4>
        <b-table-simple v-if="users" striped hover>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th>#</b-th>
              <b-th>Nom d'utilisateur</b-th>
              <b-th>Nom</b-th>
              <b-th>Prénom</b-th>
              <b-th>Adresse email</b-th>
              <b-th>Rôle</b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="u.id" v-for="u in users">
              <b-th
                ><b-avatar
                  variant="secondary"
                  :src="'/ressources/pictures/' + u.profile_picture"
                  :text="u.firstname.charAt(0) + u.lastname.charAt(0)"
                ></b-avatar
              ></b-th>
              <b-th>{{ u.id }}</b-th>
              <b-td>{{ u.username }}</b-td>
              <b-td>{{ u.lastname }}</b-td>
              <b-td>{{ u.firstname }}</b-td>
              <b-td>{{ u.email }}</b-td>
              <b-td>
                <b-select
                  @change="handle_role_change($event, user)"
                  v-if="user.id !== u.id"
                  :value="u.role"
                  :options="role_options"
                ></b-select>
                <span v-else>{{ u.role === 1 ? "Éditeur" : "Visiteur" }}</span>
              </b-td>
              <b-td
                ><b-button
                  v-b-modal.modal-delete-user
                  :disabled="user.id === u.id"
                  @click="user_to_delete = u.id"
                  pill
                  variant="danger"
                  size="sm"
                  title="Supprimer l'utilisateur"
                  ><font-awesome-icon icon="trash"
                /></b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-button v-b-modal.modal-create-user variant="primary" size="sm"
          >Ajouter un utilisateur</b-button
        >
      </div>
    </div>
    <b-modal id="modal-create-user" title="Créer un nouvel utilisateur">
      <label for="input-username">Nom d'utilisateur :</label>
      <input
        v-model="new_user_username"
        class="form-control"
        type="text"
        id="input-username"
      />

      <label for="input-email">Adresse email :</label>
      <input
        v-model="new_user_email"
        class="form-control"
        type="email"
        id="input-email"
      />

      <label for="input-lastname">Nom :</label>
      <input
        v-model="new_user_lastname"
        class="form-control"
        type="text"
        id="input-lastname"
      />

      <label for="input-firstname">Prénom :</label>
      <input
        v-model="new_user_firstname"
        class="form-control"
        type="text"
        id="input-firstname"
      />

      <label for="select-role">Rôle :</label>
      <b-select
        id="select-role"
        v-model="new_user_role"
        :options="role_options"
      ></b-select>

      <label for="input-password">Mot de passe :</label>
      <input
        v-model="new_user_password"
        class="form-control"
        type="password"
        id="input-password"
      />

      <label for="input-confirm-password">Confirmer mot de passe :</label>
      <input
        v-model="new_user_confirm_password"
        class="form-control"
        type="password"
        id="input-confirm-password"
      />
      <template #modal-footer>
        <b-button
          variant="secondary"
          class="float-right"
          @click="$bvModal.hide('modal-create-user')"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="handle_create_user"
        >
          Créer
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-delete-user" title="Supprimer ?">
      <p class="my-4">
        Es-tu sûr de vouloir supprimer cet utilisateur ? L'opération n'est pas
        réversible !
      </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">
          Oups !
        </b-button>
        <b-button variant="danger" @click="handle_delete_user">
          Oui supprimer
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Settings",
  data() {
    return {
      error: "",
      role_options: [
        { value: 0, text: "Visiteur" },
        { value: 1, text: "Éditeur" }
      ],
      new_user_username: "",
      new_user_email: "",
      new_user_lastname: "",
      new_user_firstname: "",
      new_user_password: "",
      new_user_confirm_password: "",
      new_user_role: 0,
      user_to_delete: null
    };
  },
  computed: {
    ...mapGetters({ users: "user/getAllUsers", user: "user/getUser" })
  },
  methods: {
    ...mapActions({
      fetch_all_users: "user/fetch_all_users",
      update_user: "user/update_profile",
      create_user: "user/create_user",
      delete_user: "user/delete_user"
    }),
    handle_role_change(newRole, user) {
      this.update_user({
        ...user,
        role: newRole,
        profile_picture: null,
        old_picture: null
      }).catch(err => {
        this.error = JSON.parse(err.message);
      });
    },
    handle_create_user() {
      this.create_user({
        username: this.new_user_username,
        password: this.new_user_password,
        confirm_password: this.new_user_confirm_password,
        email: this.new_user_email,
        lastname: this.new_user_lastname,
        firstname: this.new_user_firstname,
        role: this.new_user_role
      })
        .then(() => {
          this.$bvModal.hide("modal-create-user");
          this.error = "";
          this.fetch_all_users();
        })
        .catch(err => {
          this.error = JSON.parse(err.message);
        });
    },
    handle_delete_user() {
      this.delete_user(this.user_to_delete)
        .then(() => {
          this.error = "";
          this.$bvModal.hide("modal-delete-user");
          this.fetch_all_users();
        })
        .catch(err => {
          this.error = JSON.parse(err.message);
        });
    }
  },
  mounted() {
    this.fetch_all_users();
  }
};
</script>

<style scoped>
.card-body {
  text-align: left;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"back"},[_c('b-button',{attrs:{"href":"#","variant":"link"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Retour")],1)],1),(_vm.isLoading)?_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}}):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" "+_vm._s(_vm.current_post ? "Éditer un voyage" : "Créer un nouveau voyage")+" ")])]),_c('div',{staticClass:"card-body"},[(_vm.error.length)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},_vm._l((_vm.error),function(err){return _c('p',{key:err.param},[_vm._v(_vm._s(err.msg))])}),0):_vm._e(),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Image :","label-for":"input-image"}},[_c('div',{staticClass:"image-preview"},[_c('img',{attrs:{"src":_vm.new_picture
                ? _vm.new_picture
                : _vm.current_post && _vm.current_post.img
                ? '/ressources/pictures/' + _vm.current_post.img
                : _vm.default_image}})]),_c('input',{ref:"uploadPicture",attrs:{"id":"input-image","type":"file","accept":"image/*"},on:{"change":_vm.handle_picture_change}})]),_c('b-form-group',{staticClass:"mb-0 mt-3",attrs:{"label":"Titre :","label-for":"input-title"}},[_c('b-form-input',{attrs:{"id":"input-title","placeholder":"Entrer un titre"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('b-form-group',{staticClass:"mb-0 mt-3",attrs:{"label":"Résumé :","label-for":"input-blurb","description":_vm.remain_char_blurb + ' restants'}},[_c('b-form-textarea',{attrs:{"id":"input-blurb","state":_vm.remain_char_blurb >= 0 ? null : false,"rows":"3","placeholder":"Résumé du voyage, en maximum 300 caractères"},model:{value:(_vm.blurb),callback:function ($$v) {_vm.blurb=$$v},expression:"blurb"}})],1),_c('b-form-group',{staticClass:"mb-0 mt-3",attrs:{"label":"Endroits visités :","label-for":"select-places"}},[_c('v-select',{attrs:{"id":"select-places","placeholder":"Endroits visités (dans l'ordre)","multiple":"","options":_vm.places,"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.selected_places),callback:function ($$v) {_vm.selected_places=$$v},expression:"selected_places"}})],1),_c('b-form-checkbox',{staticClass:"mt-3",attrs:{"switch":""},model:{value:(_vm.show_map),callback:function ($$v) {_vm.show_map=$$v},expression:"show_map"}},[_vm._v("Afficher l'itinéraire sur une carte dans la publication")]),_c('b-form-group',{staticClass:"mb-0 mt-3",attrs:{"label":"Dates :","label-for":"date-range"}},[_c('v-date-picker',{attrs:{"id":"date-range","from-page":{
            month: new Date(Date.parse(_vm.dates.start)).getMonth() + 1,
            year: new Date(Date.parse(_vm.dates.start)).getFullYear()
          },"first-day-of-week":2,"model-config":{ type: 'string', mask: 'YYYY-MM-DD' },"locale":"fr","is-range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('hr'),_c('h4',[_vm._v("Article :")]),_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('div',{staticClass:"card-footer"},[_c('b-overlay',{attrs:{"show":_vm.isSaving,"rounded":"sm"}},[(!_vm.current_post || !_vm.current_post.is_published)?_c('b-button',{attrs:{"disabled":_vm.remain_char_blurb < 0,"variant":"primary"},on:{"click":function($event){return _vm.save_post(0)}}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Enregistrer le brouillon")],1):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"disabled":_vm.remain_char_blurb < 0 || _vm.isSaving,"variant":"success"},on:{"click":function($event){return _vm.save_post(1)}}},[_c('font-awesome-icon',{attrs:{"icon":"check"}}),_vm._v(" Publier")],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
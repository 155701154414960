<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand
        href="#"
        @click="handleGoHome"
        title="Feel the taste of Liberty!"
      >
        <img src="@/assets/images/logo.png" width="50" />
        Horizon Project
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="user">
          <b-nav-item href="#" @click="handleGoDiary">Journal</b-nav-item>
          <b-nav-item href="#" @click="handleGoPlaces">Voyages</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" v-if="user">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar
                variant="secondary"
                :src="'/ressources/pictures/' + user.profile_picture"
                :text="user.firstname.charAt(0) + user.lastname.charAt(0)"
              ></b-avatar>
            </template>
            <b-dropdown-item href="#" @click="handleGoProfile">
              Mon Profil
            </b-dropdown-item>
            <b-dropdown-item
              v-if="user.role === 1"
              href="#"
              @click="handleGoSettings"
              ><span class="blue">Paramètres</span></b-dropdown-item
            >
            <b-dropdown-item href="#" @click="handleLogout"
              ><span class="red">Se déconnecter</span></b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import UserUtils from "../utils/userUtils";
import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      public_path: process.env.BASE_URL
    };
  },
  computed: {
    ...mapGetters({ user: "user/getUser" }),
  },
  methods: {
    handleLogout() {
      const userUtils = new UserUtils();
      userUtils.logout();
      this.$router.push({name: "Login", params: {from: this.$route.fullPath || ""}})
    },
    handleGoHome() {
      this.$router.push({ name: "Home" }).catch(err => {
        console.info(err.message);
      });
    },
    handleGoDiary() {
      this.$router.push({ name: "Diary" }).catch(err => {
        console.info(err.message);
      });
    },
    handleGoPlaces() {
      this.$router.push({ name: "Travels" }).catch(err => {
        console.info(err.message);
      });
    },
    handleGoProfile() {
      this.$router.push({ name: "Profile" }).catch(err => {
        console.info(err.message);
      });
    },
    handleGoSettings() {
      this.$router.push({ name: "Settings" }).catch(err => {
        console.info(err.message);
      });
    }
  }
};
</script>

<style scoped>
.red {
  color: #f33200;
}
.blue {
  color: #105e82;
}
</style>

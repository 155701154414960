<template>
  <div>
    <div class="filter">
      <button
        title="Filtrer"
        class="btn btn-secondary"
        v-b-toggle.sidebar-filter
      >
        <font-awesome-icon icon="filter" />
      </button>
    </div>
    <b-sidebar id="sidebar-filter" title="Filtrer" right shadow backdrop>
      <div class="px-3 py-2">
        <p>
          Filtrer les éléments de la cartes :
        </p>
        <div class="filter-container">
          <b-form-checkbox class="mt-4" v-model="visited" switch size="lg"
            ><img src="@/assets/images/green-marker.png" width="20" />
            Visités</b-form-checkbox
          >
          <b-form-checkbox v-model="not_visited" switch size="lg"
            ><img src="@/assets/images/red-marker.png" width="20" /> Non
            Visités</b-form-checkbox
          >
          <label class="mt-4" for="date-after">Après le</label>
          <b-form-datepicker
            placeholder="Pas de date sélectionnée"
            id="date-after"
            locale="fr-FR"
            start-weekday="1"
            label-help=""
            reset-button
            label-reset-button="Réinitialiser"
            v-model="date_after"
            class="mb-2"
          ></b-form-datepicker>
          <label for="date-before">Avant le</label>
          <b-form-datepicker
            placeholder="Pas de date sélectionnée"
            id="date-before"
            locale="fr-FR"
            start-weekday="1"
            label-help=""
            reset-button
            label-reset-button="Réinitialiser"
            v-model="date_before"
            class="mb-2"
          ></b-form-datepicker>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MapFilter",
  computed: {
    ...mapGetters({ filters: "map/getFilters" }),
    visited: {
      get() {
        return this.filters.visited;
      },
      set(newValue) {
        this.update_filters({ ...this.filters, visited: newValue });
      }
    },
    not_visited: {
      get() {
        return this.filters.not_visited;
      },
      set(newValue) {
        this.update_filters({ ...this.filters, not_visited: newValue });
      }
    },
    date_after: {
      get() {
        return this.filters.date_after;
      },
      set(newValue) {
        this.update_filters({ ...this.filters, date_after: newValue });
      }
    },
    date_before: {
      get() {
        return this.filters.date_before;
      },
      set(newValue) {
        this.update_filters({ ...this.filters, date_before: newValue });
      }
    }
  },
  methods: {
    ...mapMutations("map", ["update_filters"])
  }
};
</script>

<style scoped>
.filter {
  position: fixed;
  z-index: 8;
  top: 90px;
  right: 10px;
}
.filter-container {
  text-align: left;
}
</style>

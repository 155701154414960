<template>
  <div class="container mb-5">
    <div class="card">
      <div class="card-header">
        <h3>Mon profil</h3>
      </div>
      <div class="card-body" v-if="user">
        <div class="alert alert-danger" role="alert" v-if="error.length">
          <p :key="err.param" v-for="err in error">{{ err.msg }}</p>
        </div>
        <b-avatar
          title="Change ton image"
          button
          @click="$refs.uploadPicture.click()"
          size="100px"
          variant="secondary"
          :src="
            new_picture
              ? new_picture
              : '/ressources/pictures/' + user.profile_picture
          "
          :text="user.firstname.charAt(0) + user.lastname.charAt(0)"
        ></b-avatar>
        <input
          ref="uploadPicture"
          type="file"
          accept="image/*"
          @change="handle_picture_change"
          hidden
        />
        <div class="profile-form">
          <div class="row mt-5">
            <div class="col-md-6">
              <label for="inputEmail" class="form-label"
                >Mon adresse email :</label
              >
              <input
                type="email"
                class="form-control"
                id="inputEmail"
                @input="handle_input_change($event, 'email')"
                :value="user.email"
              />
            </div>
            <div class="col-md-6">
              <label for="inputUsername" class="form-label">Mon pseudo :</label>
              <input
                type="text"
                class="form-control"
                id="inputUsername"
                @input="handle_input_change($event, 'username')"
                :value="user.username"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label for="inputLastname" class="form-label">Mon nom :</label>
              <input
                type="text"
                class="form-control"
                id="inputLastname"
                @input="handle_input_change($event, 'lastname')"
                :value="user.lastname"
              />
            </div>
            <div class="col-md-6 mb-3">
              <label for="inputFirstname" class="form-label"
                >Mon prénom :</label
              >
              <input
                type="text"
                class="form-control"
                id="inputFirstname"
                @input="handle_input_change($event, 'firstname')"
                :value="user.firstname"
              />
            </div>
          </div>

          <b-button v-b-modal.modal-change-password variant="link"
            >Changer mon mot de passe</b-button
          >
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary"
              @click="update_user_profile"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-change-password" title="Changer mon mot de passe">
      <label for="input-old-password">Ancien mot de passe :</label>
      <input
        v-model="old_password"
        class="form-control"
        type="password"
        id="input-old-password"
      />

      <label for="input-new-password">Nouveau mot de passe :</label>
      <input
        v-model="new_password"
        class="form-control"
        type="password"
        id="input-new-password"
      />
      <template #modal-footer>
        <b-button
          variant="secondary"
          class="float-right"
          @click="$bvModal.hide('modal-change-password')"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="handle_change_password"
        >
          Changer
        </b-button>
      </template>
    </b-modal>

    <b-alert
      :show="showToast"
      fade
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismiss-count-down="countDownChanged"
    >
      Mise à jour du profil effectuée !
    </b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      error: [],
      showToast: 0,
      selected_file: "",
      new_picture: null,
      userModel: {},
      old_password: "",
      new_password: ""
    };
  },
  computed: {
    ...mapGetters({ user: "user/getUser" })
  },
  methods: {
    ...mapActions({
      update_profile: "user/update_profile",
      update_password: "user/update_password"
    }),
    handle_input_change(event, field) {
      this.userModel[field] = event.target.value;
    },
    handle_picture_change(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.selected_file = {
          name: event.target.files[0].name,
          file: reader.result
        };
      };
      this.new_picture = URL.createObjectURL(event.target.files[0]);
    },
    async update_user_profile() {
      const newUser = {};
      this.error = [];

      for (let key in this.user) {
        if (key !== "profile_picture")
          newUser[key] =
            this.userModel[key] && this.userModel[key] !== ""
              ? this.userModel[key]
              : this.user[key];
      }

      // Necessary for the backend to remove the old user's picture if upload
      newUser["old_picture"] = this.user.profile_picture;

      if (this.selected_file !== "") {
        newUser["profile_picture"] = this.selected_file;
      } else newUser["profile_picture"] = null;

      this.update_profile(newUser)
        .then(() => {
          this.showToast = 5;
          this.selected_file = "";
        })
        .catch(err => {
          this.error = JSON.parse(err.message);
        })
        .finally(() => {
          this.userModel = {};
        });
    },
    handle_change_password() {
      this.update_password({
        old_password: this.old_password,
        new_password: this.new_password
      })
        .then(() => {
          this.showToast = 5;
          this.$bvModal.hide("modal-change-password");
          this.old_password = "";
          this.new_password = "";
        })
        .catch(err => {
          this.error = JSON.parse(err.message);
        });
    },
    countDownChanged(newCount) {
      this.showToast = newCount;
    }
  }
};
</script>

<style scoped>
.profile-form {
  text-align: left;
}
#upload-label {
  display: none;
}
</style>

<template>
  <div class="container mb-5" v-if="user">
    <h3>Journal de voyage</h3>

    <b-spinner variant="primary" label="Spinning" v-if="isLoading"></b-spinner>
    <b-button
      v-if="user.role === 1"
      class="create-button"
      size="sm"
      pill
      variant="success"
      @click="$router.push({ name: 'NewPost' })"
      ><font-awesome-icon icon="plus" /> Créer</b-button
    >
    <b-button
      v-if="user.role === 1"
      class="draft-button"
      size="sm"
      pill
      variant="light"
      :pressed.sync="draft_pressed"
      @click="handle_drafts"
      ><font-awesome-icon icon="file-alt" /> Mes brouillons</b-button
    >

    <transition appear name="fade" tag="div">
      <div class="mt-3 mb-3 diary-container" v-if="!isLoading">
        <Post v-for="post in posts" :key="post.id" :post="post" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Post from "@/components/Post";

export default {
  name: "Diary",
  components: { Post },
  data() {
    return {
      isLoading: true,
      draft_pressed: false
    };
  },
  computed: {
    ...mapGetters({ posts: "post/getPosts", user: "user/getUser" })
  },
  methods: {
    ...mapActions({
      fetch_posts: "post/fetch_all_posts",
      fetch_drafts: "post/fetch_drafts",
      reset_current_post: "post/reset_current_post"
    }),

    handle_drafts() {
      if (this.draft_pressed) this.fetch_drafts();
      else this.fetch_posts();
    }
  },
  async mounted() {
    await this.fetch_posts();
    this.reset_current_post();
    this.isLoading = false;
  }
};
</script>

<style scoped>
.draft-button {
  margin-left: 20px;
}
.diary-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-enter .fade-leave {
  opacity: 0;
}
</style>

<template>
  <div class="container">
    <b-button href="#" variant="link" @click="$router.push({ name: 'Diary' })"
      ><font-awesome-icon icon="angle-left" /> Retour</b-button
    >
    <transition name="fade">
      <div class="post-container" v-if="post && user">
        <div class="row">
          <div class="col-md-8 post-body mb-3">
            <div class="card mb-3">
              <div class="card-body">
                <b-button
                  v-if="user.role === 1"
                  class="edit-button"
                  variant="danger"
                  size="sm"
                  pill
                  v-b-modal.modal-delete
                  ><font-awesome-icon icon="trash"
                /></b-button>
                <b-button
                  v-if="user.role === 1"
                  class="edit-button mb-2"
                  variant="light"
                  size="sm"
                  pill
                  @click="handle_edit"
                  ><font-awesome-icon icon="pencil-alt"
                /></b-button>
                <div class="post-img"><img :src="post_img" /></div>
                <div class="post-title">
                  {{ post.title }}
                  <b-avatar
                    style="float: right; margin-top: 10px;"
                    variant="secondary"
                    :src="'/ressources/pictures/' + post.user.profile_picture"
                    :text="
                      post.user.firstname.charAt(0) +
                        post.user.lastname.charAt(0)
                    "
                  ></b-avatar>
                </div>
                <div class="post-meta" v-if="post.is_published">
                  Le {{ format_date_time(post.published_date) }}, par
                  {{ post.user.firstname }}
                </div>
                <p>{{ post.blurb }}</p>
                <hr />
                <div class="post-body" v-html="post.text"></div>
              </div>
            </div>
            <PostComments />
          </div>
          <div class="col-md-4 sidebar mb-5">
            <PostDates />
            <PostItinerary :post="post" v-if="post.show_itinerary" />
            <PostPlaces />
          </div>
        </div>
      </div>
    </transition>
    <b-modal id="modal-delete" title="Supprimer ?">
      <p class="my-4">
        Es-tu sûr de vouloir supprimer cette article ? L'opération n'est pas
        réversible !
      </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">
          Oups !
        </b-button>
        <b-button variant="danger" @click="handle_delete">
          Oui supprimer
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import PostItinerary from "@/components/PostItinerary";
import PostDates from "@/components/PostDates";
import PostPlaces from "@/components/PostPlaces";
import PostComments from "@/components/PostComments";

export default {
  name: "Post",
  components: { PostComments, PostPlaces, PostDates, PostItinerary },
  computed: {
    ...mapGetters({ post: "post/getCurrentPost", user: "user/getUser" }),
    post_img() {
      const base_url = "/ressources/pictures/";
      return this.post.img
        ? base_url + this.post.img
        : base_url + "defaultnoimage.jpg";
    }
  },
  methods: {
    ...mapActions({
      fetch_post: "post/fetch_post",
      delete_post: "post/delete_post"
    }),
    format_date_time(date) {
      moment.locale("fr");
      return moment.utc(date).format("dddd Do MMMM YYYY, HH:mm");
    },
    format_date(date, type) {
      moment.locale("fr");
      return moment.utc(date).format("dddd Do MMMM YYYY");
    },
    handle_edit() {
      this.$router.push(`/posts/${this.$route.params.id}/edit`);
    },
    handle_delete() {
      this.delete_post().then(() => {
        this.$router.push({ name: "Diary" });
      });
    }
  },
  mounted() {
    this.fetch_post(this.$route.params.id);
  }
};
</script>

<style scoped>
.container {
  text-align: left;
}
.edit-button {
  float: right;
}
.post-img > img {
  max-width: 100%;
}
.post-title {
  margin-bottom: 0.25rem;
  font-size: 2rem;
  text-align: left;
}
.post-meta {
  margin-bottom: 1.25rem;
  color: #999;
}
.post-body {
  text-align: justify;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
</style>

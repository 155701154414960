<template>
  <div class="footer">
    <p>Horizon Project - V2.0</p>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 5px;
  left: 10px;
  z-index: 10;
  color: #2252cd;
  font-size: 0.8rem;
  font-style: italic;
}
</style>

<template>
  <div class="itinerary-container">
    <div class="card">
      <div class="card-body">
        <h4>Itinéraire</h4>
        <div class="map-container">
          <Map
            width="calc(100%)"
            height="200px"
            position="relative"
            :places="post.places"
            :center="map_center"
            :zoom="4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/Map";
import { mapGetters } from "vuex";
export default {
  name: "PostItinerary",
  components: { Map },
  computed: {
    ...mapGetters({ post: "post/getCurrentPost" }),
    map_center() {
      const allX = this.post.places.map(place => place.longitude);
      const allY = this.post.places.map(place => place.latitude);
      return [
        allY.reduce((a, b) => a + b, 0) / allY.length,
        allX.reduce((a, b) => a + b, 0) / allX.length
      ];
    }
  }
};
</script>

<style scoped>
.itinerary-container {
  margin-top: 20px;
}
</style>

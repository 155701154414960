<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>Se Connecter</h3>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ error }}
        </div>
        <div>
          <div class="form-group">
            <label>Nom d'utilisateur</label>
            <input
              v-model="username"
              type="text"
              class="form-control form-control-lg"
            />
          </div>

          <div class="form-group">
            <label>Mot de passe</label>
            <input
              v-model="password"
              type="password"
              @keyup="handleKeyUp"
              class="form-control form-control-lg"
            />
          </div>

          <b-overlay :show="isLoading" rounded="sm">
            <button
              type="button"
              class="btn btn-dark btn-lg btn-block"
              @click="handleLogin"
            >
              Connexion
            </button>
          </b-overlay>

          <p class="forgot-password text-right mt-2 mb-4">
            <router-link to="/forgot-password"
              >Mot de passe oublié ?</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import UserUtils from "@/utils/userUtils";

export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      error: ""
    };
  },
  methods: {
    ...mapMutations({ update_user: "user/update_user" }),
    handleLogin() {
      const userUtils = new UserUtils();
      this.error = "";
      this.isLoading = true;
      userUtils
        .login(this.username, this.password)
        .then(user => {
          this.update_user(user);
          this.$router.push({
            path: this.$route.params.from ? this.$route.params.from : "/"
          });
        })
        .catch(err => {
          console.error(err);
          this.error = err.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleKeyUp(event) {
      if (event.keyCode === 13) this.handleLogin();
    }
  }
};
</script>

<style scoped></style>

import PlaceUtils from "../../utils/placeUtils";
import { Place } from "../../models/Place";
import { MapFilter } from "../../models/MapFilter";
import { Country } from "../../models/Country";

const store = {
  state: () => ({
    places: [],
    filters: {
      visited: true,
      not_visited: true,
      date_before: null,
      date_after: null
    },
    countries: [],
    current_place: null
  }),
  mutations: {
    update_places(state: any, places: Place[]) {
      state.places = places;
    },
    update_current_place(state: any, place: Place) {
      state.current_place = place;
    },
    update_filters(state: any, filter: MapFilter) {
      state.filters = filter;
    },
    update_countries(state: any, countries: Country[]) {
      state.countries = countries;
    }
  },
  actions: {
    async fetch_places(context: any) {
      const placeUtils = new PlaceUtils();
      await placeUtils.fetchAllPlaces().then((places: Place[]) => {
        context.commit("update_places", places);
      });
    },
    async fetch_place(context: any, id: number) {
      const placeUtils = new PlaceUtils();
      await placeUtils.fetchPlaceById(id).then((place: Place) => {
        context.commit("update_current_place", place);
      });
    },
    async reset_current_place(context: any) {
      context.commit("update_current_place", null);
    },
    async fetch_countries(context: any) {
      const placeUtils = new PlaceUtils();
      await placeUtils.fetchAllCountries().then((countries: Country[]) => {
        context.commit("update_countries", countries);
      });
    },
    async add_place(context: any, place: Place) {
      const placeUtils = new PlaceUtils();
      return placeUtils.addPlace(place).then((newPlace: Place) => {
        context.commit("update_current_place", newPlace);
        return newPlace.id;
      });
    },
    async update_place(context: any, place: Place) {
      const placeUtils = new PlaceUtils();
      await placeUtils.updatePlace(place).then((newPlace: Place) => {
        context.commit("update_current_place", newPlace);
      });
    },
    async delete_place(context: any) {
      const placeUtils = new PlaceUtils();
      await placeUtils.deletePlace(context.state.current_place.id);
    }
  },
  getters: {
    getPlaces: (state: any) => state.places,
    getFilters: (state: any) => state.filters,
    getCountries: (state: any) => state.countries,
    getCurrentPlace: (state: any) => state.current_place
  }
};

export default store;

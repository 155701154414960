<template>
  <div id="container">
    <div
      id="mapContainer"
      :style="{ width: width, height: height, position: position }"
    ></div>
    <div
      v-show="false"
      :ref="'popup-' + place.id"
      :key="place.id"
      v-for="place in filtered_places"
    >
      <b-button variant="link" size="sm" @click="handle_click_place(place.id)"
        ><span
          :class="'flag-icon flag-icon-' + place.country.code.toLowerCase()"
        ></span>
        {{ place.name }}</b-button
      >
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { mapGetters } from "vuex";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

let redIcon = L.icon({
  iconUrl: require("/src/assets/images/red-marker.png"),
  shadowUrl: iconShadow,
  iconSize: [31, 33],
  iconAnchor: [15.1, 33],
  popupAnchor: [0, -33]
});

let greenIcon = L.icon({
  iconUrl: require("/src/assets/images/green-marker.png"),
  shadowUrl: iconShadow,
  iconSize: [31, 33],
  iconAnchor: [15.1, 33],
  popupAnchor: [0, -33]
});

let pinIcon = L.icon({
  iconUrl: require("/src/assets/images/pin.png"),
  iconSize: [44, 44],
  iconAnchor: [24, 44],
  popupAnchor: [0, -44]
});

export default {
  name: "Map",
  props: {
    width: {
      type: String,
      required: false,
      default: "600px"
    },
    height: {
      type: String,
      required: false,
      default: "400px"
    },
    position: {
      type: String,
      required: false,
      default: "relative"
    },
    center: {
      type: Array,
      required: false,
      default: () => [40, 16]
    },
    zoom: {
      type: Number,
      required: false,
      default: 4
    },
    places: {
      type: Array,
      required: true,
      default: () => []
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    initialMarker: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      mapDiv: null,
      markerLayer: null,
      bounds: null
    };
  },
  computed: {
    ...mapGetters({ filters: "map/getFilters" }),
    filtered_places() {
      if (!this.places) return [];
      if (!this.filters) return this.places;
      return this.places.filter(place => {
        return (
          ((this.filters.visited && place.done) ||
            (this.filters.not_visited && !place.done)) &&
          (!place.done ||
            ((!this.filters.date_after ||
              Date.parse(place.date_done) >=
                Date.parse(this.filters.date_after)) &&
              (!this.filters.date_before ||
                Date.parse(place.date_done) <=
                  Date.parse(this.filters.date_before))))
        );
      });
    }
  },
  watch: {
    filters() {
      this.set_places();
    }
  },
  methods: {
    setup_leaflet_map() {
      this.mapDiv = L.map("mapContainer", { tap: false }).setView(
        this.center,
        this.zoom
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
          id: "jlammens/ckn1fxa8b22wp17n5u9jx8i1e",
          accessToken:
            "pk.eyJ1IjoiamxhbW1lbnMiLCJhIjoiY2ttNG5ieGJwMDY3NzJ2cDFnYmZqNHg4eSJ9.hvo04Bvf0DDj7U11Q_Vqow"
        }
      ).addTo(this.mapDiv);

      const search = new GeoSearchControl({
        style: "button",
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        showPopup: false,
        notFoundMessage: "Aucun résultat",
        autoClose: true
      });
      this.mapDiv.addControl(search);

      this.markerLayer = L.layerGroup();
      this.markerLayer.addTo(this.mapDiv);
      if (this.editable) {
        this.mapDiv.on("click", this.handle_click);
      }
      if (this.initialMarker.length) {
        this.add_marker(this.initialMarker);
      }
    },
    set_places() {
      if (this.markerLayer && this.initialMarker.length === 0)
        this.markerLayer.clearLayers();
      this.filtered_places.forEach(place => {
        const marker = L.marker([place.latitude, place.longitude], {
          icon: place.done ? greenIcon : redIcon
        });
        const ref = `popup-${place.id}`;

        marker.bindPopup(() => {
          const popup_elt = this.$refs[ref][0];
          popup_elt.style.display = "block";
          return popup_elt;
        });
        marker.addTo(this.markerLayer);
      });
    },
    add_marker(coordinates) {
      const marker = L.marker(coordinates, {
        icon: pinIcon
      });
      this.markerLayer.clearLayers();
      marker.addTo(this.markerLayer);
    },
    handle_click(event) {
      this.add_marker([event.latlng.lat, event.latlng.lng]);
      this.$emit("click", {
        latitude: event.latlng.lat,
        longitude: event.latlng.lng
      });
    },
    handle_click_place(place_id) {
      this.$router.push({ name: "Place", params: { id: place_id } });
    }
  },
  async mounted() {
    this.setup_leaflet_map();
    this.set_places();
  }
};
</script>

<style scoped>
#mapContainer {
  position: absolute;
  z-index: 5;
}
</style>

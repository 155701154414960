import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import Home from "@/views/Home.vue";
import Diary from "@/views/Diary.vue";
import Post from "@/views/Post.vue";
import NewPost from "@/views/NewPost.vue";
import Travels from "@/views/Travels.vue";
import Place from "@/views/Place.vue";
import NewPlace from "@/views/NewPlace.vue";
import Login from "@/views/Login.vue";
import Settings from "@/views/Settings.vue";
import Profile from "@/views/Profile.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import UserUtils from "../utils/userUtils";
import $store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "",
    name: "Home",
    component: Home
  },
  {
    path: "/posts",
    name: "Diary",
    component: Diary
  },
  {
    path: "/posts/new",
    name: "NewPost",
    component: NewPost,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      beforeEnterProtected(to, from, next);
    }
  },
  {
    path: "/posts/:id",
    name: "Post",
    component: Post
  },
  {
    path: "/posts/:id/edit",
    name: "EditPost",
    component: NewPost,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      beforeEnterProtected(to, from, next);
    }
  },
  {
    path: "/places",
    name: "Travels",
    component: Travels
  },
  {
    path: "/places/new",
    name: "NewPlace",
    component: NewPlace,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      beforeEnterProtected(to, from, next);
    }
  },
  {
    path: "/places/:id",
    name: "Place",
    component: Place
  },
  {
    path: "/places/:id/edit",
    name: "EditPlace",
    component: NewPlace,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      beforeEnterProtected(to, from, next);
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      beforeEnterProtected(to, from, next);
    }
  },
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  const userUtils = new UserUtils();
  if (
    to.name !== "Login" &&
    userUtils.isAuthenticated() &&
    !$store.getters["user/getUser"]
  ) {
    $store.dispatch("user/fetch_user");
    next();
  } else if (to.name === "Login" && userUtils.isAuthenticated())
    next({ name: "Home" });
  else if (to.name !== "Login" && !userUtils.isAuthenticated())
    next({name: "Login", params: {from: to.fullPath || ""}});
  else next();
});

const beforeEnterProtected = async (to, from, next) => {
  let user = $store.getters["user/getUser"];
  if (!user) {
    await $store.dispatch("user/fetch_user");
    user = $store.getters["user/getUser"];
  }
  if (user.role !== 1) {
    next({ name: "Home" });
  } else next();
};

export default router;

import RequestUtils from "./requestUtils";
import { Place } from "../models/Place";
import { Country } from "../models/Country";
import { Post } from "../models/Post";

export default class PlaceUtils extends RequestUtils {
  fetchAllPlaces(): Promise<Place[]> {
    return this.ax
      .get("/place")
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchPlaceById(id: number): Promise<Place> {
    return this.ax
      .get(`/place/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchAllCountries(): Promise<Country[]> {
    return this.ax
      .get("/place/countries")
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  addPlace(place: Place): Promise<any> {
    return this.ax
      .post(`/place`, place)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updatePlace(place: Place): Promise<any> {
    return this.ax
      .put(`/place/${place.id}`, place)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  deletePlace(id: number): Promise<any> {
    return this.ax
      .delete(`/place/${id}`)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }
}

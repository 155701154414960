<template>
  <div class="container mb-5">
    <div class="back-button">
      <b-button href="#" variant="link" @click="$router.go(-1)"
        ><font-awesome-icon icon="angle-left" /> Retour</b-button
      >
    </div>
    <b-spinner variant="primary" label="Spinning" v-if="isLoading"></b-spinner>
    <div class="card" v-else>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="error.length">
          <p :key="err.param" v-for="err in error">{{ err.msg }}</p>
        </div>
        <div class="row">
          <div class="col-md-7 mb-3">
            <Map
              v-on:click="handle_map_click"
              width="calc(100%)"
              :places="[]"
              :editable="true"
              :initial-marker="
                current_place
                  ? [current_place.latitude, current_place.longitude]
                  : []
              "
              :center="
                current_place
                  ? [current_place.latitude, current_place.longitude]
                  : [40, 16]
              "
            />
          </div>
          <div class="col-md-5">
            <h5>
              {{
                current_place
                  ? "Editer un endroit"
                  : "Ajouter un nouvel endroit à visiter"
              }}
            </h5>
            <div class="place-info row mt-3">
              <div class="col-md-6">
                <b-input-group prepend="X">
                  <b-form-input v-model="longitude" disabled></b-form-input>
                </b-input-group>
              </div>

              <div class="col-md-6">
                <b-input-group prepend="Y">
                  <b-form-input v-model="latitude" disabled></b-form-input>
                </b-input-group>
              </div>
            </div>

            <div class="place-info row mt-3">
              <div class="col-md-6">
                <label for="inputName" class="form-label">Nom :</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputName"
                  v-model="name"
                />
              </div>
              <div class="col-md-6">
                <label for="selectCountry" class="form-label">Pays :</label>
                <b-form-select
                  id="selectCountry"
                  v-model="country"
                  :options="countries"
                  value-field="id"
                  text-field="name_fr"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >Sélectionner un pays</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="place-info row mt-3">
              <div class="col-md-12">
                <label for="input-description" class="form-label"
                  >Description :</label
                >
                <b-form-textarea
                  id="input-description"
                  v-model="description"
                  placeholder="Décris l'endroit"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
            <b-overlay :show="isSaving" rounded="sm">
              <b-button
                class="mt-3"
                :disabled="isSaving"
                variant="primary"
                @click="save_place"
                >Enregistrer</b-button
              >
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/Map";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewPlace",
  data() {
    return {
      isLoading: true,
      isSaving: false,
      longitude: "",
      latitude: "",
      name: "",
      country: null,
      description: "",
      error: ""
    };
  },
  components: { Map },
  computed: {
    ...mapGetters({
      countries: "map/getCountries",
      current_place: "map/getCurrentPlace"
    })
  },
  methods: {
    ...mapActions({
      reset_current_place: "map/reset_current_place",
      fetch_place: "map/fetch_place",
      fetch_countries: "map/fetch_countries",
      add_place: "map/add_place",
      update_place: "map/update_place"
    }),
    handle_map_click(coordinates) {
      this.longitude = coordinates.longitude;
      this.latitude = coordinates.latitude;
    },
    save_place() {
      this.isSaving = true;
      if (!this.current_place) {
        this.add_place({
          name: this.name,
          longitude: this.longitude,
          latitude: this.latitude,
          country_id: this.country,
          description: this.description
        })
          .then(() => {
            this.$router.push({
              name: "Travels"
            });
          })
          .catch(err => {
            console.error(err);
            this.error = JSON.parse(err.message);
          })
          .finally(() => (this.isSaving = false));
      } else {
        this.update_place({
          id: this.current_place.id,
          name: this.name,
          longitude: this.longitude,
          latitude: this.latitude,
          country_id: this.country,
          description: this.description,
          done: this.current_place.done,
          date_done: this.current_place.date_done
        })
          .then(() => {
            this.$router.push({
              name: "Travels"
            });
          })
          .catch(err => {
            console.error(err);
            this.error = JSON.parse(err.message);
          })
          .finally(() => (this.isSaving = false));
      }
    }
  },
  mounted() {
    this.isLoading = true;
    this.reset_current_place();
    this.fetch_countries();
    if (this.$route.params.id) {
      this.fetch_place(this.$route.params.id).then(() => {
        this.longitude = this.current_place.longitude;
        this.latitude = this.current_place.latitude;
        this.name = this.current_place.name;
        this.country = this.current_place.country.id;
        this.description = this.current_place.description;
        this.isLoading = false;
      });
    } else this.isLoading = false;
  }
};
</script>

<style scoped>
.back-button {
  text-align: left;
}
.place-info {
  text-align: left;
}
</style>

<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-body">
        <h4>Endroits visités</h4>
        <b-badge
          class="place"
          pill
          variant="secondary"
          :key="post.id + '-' + place.id"
          v-for="place in post.places"
          >{{ place.name }}</b-badge
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PostPlaces",
  computed: {
    ...mapGetters({ post: "post/getCurrentPost" })
  }
};
</script>

<style scoped>
.place {
  margin-left: 5px;
}
</style>

<template>
  <div class="post-dates-container">
    <div class="card">
      <div class="card-body">
        <h4>Dates du voyage</h4>
        <v-calendar
          :first-day-of-week="2"
          :from-page="{
            month: this.start_date.getMonth() + 1,
            year: this.start_date.getFullYear()
          }"
          :attributes="calendar_attr"
          :min-date="this.start_date"
          :max-date="this.end_date"
          is-expanded
          locale="fr"
        ></v-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PostDates",
  computed: {
    ...mapGetters({ post: "post/getCurrentPost" }),
    start_date() {
      return new Date(Date.parse(this.post.start_date));
    },
    end_date() {
      return new Date(Date.parse(this.post.end_date));
    },
    calendar_attr() {
      return [
        {
          highlight: true,
          dates: {
            start: this.start_date,
            end: this.end_date
          }
        }
      ];
    }
  }
};
</script>

<style scoped></style>

import Axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

export default class RequestUtils {
  ax: AxiosInstance;

  constructor() {
    this.ax = Axios.create({
      baseURL: window.location.origin + "/api",
      headers: {
        "Content-Type": "application/json"
      }
    });

    this.ax.interceptors.request.use(async request => {
      request.headers["Authorization"] = Cookies.get("authorization");
      return request;
    });
  }
}

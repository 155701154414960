<template>
  <div class="container">
    <div class="back">
      <b-button href="#" variant="link" @click="$router.go(-1)"
        ><font-awesome-icon icon="angle-left" /> Retour</b-button
      >
    </div>

    <b-spinner variant="primary" label="Spinning" v-if="isLoading"></b-spinner>
    <div class="card" v-else>
      <div class="card-header">
        <h4>
          {{ current_post ? "Éditer un voyage" : "Créer un nouveau voyage" }}
        </h4>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="error.length">
          <p :key="err.param" v-for="err in error">{{ err.msg }}</p>
        </div>

        <b-form-group label="Image :" label-for="input-image" class="mb-0">
          <div class="image-preview">
            <img
              :src="
                new_picture
                  ? new_picture
                  : current_post && current_post.img
                  ? '/ressources/pictures/' + current_post.img
                  : default_image
              "
            />
          </div>
          <input
            ref="uploadPicture"
            id="input-image"
            type="file"
            accept="image/*"
            @change="handle_picture_change"
          />
        </b-form-group>

        <b-form-group label="Titre :" label-for="input-title" class="mb-0 mt-3">
          <b-form-input
            id="input-title"
            v-model="title"
            placeholder="Entrer un titre"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Résumé :"
          label-for="input-blurb"
          :description="remain_char_blurb + ' restants'"
          class="mb-0 mt-3"
        >
          <b-form-textarea
            id="input-blurb"
            v-model="blurb"
            :state="remain_char_blurb >= 0 ? null : false"
            rows="3"
            placeholder="Résumé du voyage, en maximum 300 caractères"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Endroits visités :"
          label-for="select-places"
          class="mb-0 mt-3"
        >
          <v-select
            id="select-places"
            placeholder="Endroits visités (dans l'ordre)"
            multiple
            v-model="selected_places"
            :options="places"
            :getOptionLabel="option => option.name"
          />
        </b-form-group>

        <b-form-checkbox class="mt-3" v-model="show_map" switch
          >Afficher l'itinéraire sur une carte dans la
          publication</b-form-checkbox
        >

        <b-form-group label="Dates :" label-for="date-range" class="mb-0 mt-3">
          <v-date-picker
            id="date-range"
            :from-page="{
              month: new Date(Date.parse(dates.start)).getMonth() + 1,
              year: new Date(Date.parse(dates.start)).getFullYear()
            }"
            :first-day-of-week="2"
            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
            locale="fr"
            v-model="dates"
            is-range
          />
        </b-form-group>
        <hr />
        <h4>Article :</h4>
        <vue-editor v-model="text" :editorToolbar="customToolbar"></vue-editor>
      </div>
      <div class="card-footer">
        <b-overlay :show="isSaving" rounded="sm">
          <b-button
            :disabled="remain_char_blurb < 0"
            v-if="!current_post || !current_post.is_published"
            variant="primary"
            @click="save_post(0)"
            ><font-awesome-icon icon="save" /> Enregistrer le
            brouillon</b-button
          >
          <b-button
            class="ml-2"
            :disabled="remain_char_blurb < 0 || isSaving"
            variant="success"
            @click="save_post(1)"
            ><font-awesome-icon icon="check" /> Publier</b-button
          >
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewPost",
  components: { VueEditor },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      error: "",
      selected_file: "",
      new_picture: null,
      default_image: "/ressources/pictures/defaultnoimage.jpg",
      title: "",
      blurb: "",
      selected_places: [],
      show_map: true,
      dates: {},
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"] // remove formatting button
      ],
      text: ""
    };
  },
  methods: {
    ...mapActions({
      fetch_places: "map/fetch_places",
      create_post: "post/create_post",
      update_post: "post/update_post",
      fetch_post: "post/fetch_post",
      reset_current_post: "post/reset_current_post"
    }),
    handle_picture_change(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.selected_file = {
          name: event.target.files[0].name,
          file: reader.result
        };
      };
      this.new_picture = URL.createObjectURL(event.target.files[0]);
    },
    save_post(is_published) {
      this.isSaving = true;
      const post = {
        title: this.title,
        blurb: this.blurb,
        places: this.selected_places,
        text: this.text,
        img: null,
        show_itinerary: this.show_map,
        start_date: this.dates.start,
        end_date: this.dates.end,
        is_published,
        published_date: null
      };
      if (this.selected_file !== "") post.img = this.selected_file;

      if (!this.current_post) {
        this.create_post(post)
          .then(id => {
            if (!is_published)
              this.$router.push({
                name: "EditPost",
                params: { id: id }
              });
            else
              this.$router.push({
                name: "Post",
                params: { id: id }
              });
          })
          .catch(err => {
            console.error(err);
            this.error = JSON.parse(err.message);
          })
          .finally(() => (this.isSaving = false));
      } else {
        post.id = this.current_post.id;
        post.published_date = this.current_post.published_date;
        post.old_img = this.current_post.img;
        this.update_post(post)
          .then(() => {
            this.$router.push({
              name: "Post",
              params: { id: this.current_post.id }
            });
          })
          .catch(err => {
            console.error(err);
            this.error = JSON.parse(err.message);
          })
          .finally(() => (this.isSaving = false));
      }
    }
  },
  computed: {
    ...mapGetters({
      places: "map/getPlaces",
      current_post: "post/getCurrentPost"
    }),
    remain_char_blurb() {
      return 300 - this.blurb.length;
    }
  },
  mounted() {
    this.isLoading = true;
    this.reset_current_post();
    this.fetch_places();
    if (this.$route.params.id) {
      this.fetch_post(this.$route.params.id).then(() => {
        this.title = this.current_post.title;
        this.blurb = this.current_post.blurb;
        this.selected_places = this.current_post.places;
        this.dates = {
          start: this.current_post.start_date,
          end: this.current_post.end_date
        };
        this.show_map = this.current_post.show_itinerary;
        this.text = this.current_post.text;
        this.isLoading = false;
      });
    } else this.isLoading = false;
  }
};
</script>

<style scoped>
.container {
  margin-bottom: 50px;
}
.card,
.back {
  text-align: left;
}
.image-preview {
  position: relative;
  width: 30rem;
  height: 260px;
  border: 1px solid grey;
  overflow: hidden;
}
.image-preview img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>

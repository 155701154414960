<template>
  <b-card
    :title="post.title"
    :img-src="
      post.img
        ? '/ressources/pictures/' + post.img
        : '/ressources/pictures/defaultnoimage.jpg'
    "
    :img-alt="post.title"
    img-top
    tag="article"
    style="max-width: 30rem;"
    class="mb-2"
    @click="handle_read_more(post.id)"
  >
    <div class="date">
      {{ format_date(post.published_date) }}
    </div>
    <div class="text-body">
      {{ post.blurb }}
    </div>

    <b-button href="#" variant="link" @click="handle_read_more(post.id)"
      >Lire la suite</b-button
    >
    <b-avatar
      style="float: right"
      variant="secondary"
      :title="'Rédigé par ' + post.user.firstname"
      :src="'/ressources/pictures/' + post.user.profile_picture"
      :text="post.user.firstname.charAt(0) + post.user.lastname.charAt(0)"
    ></b-avatar>
    <template #footer>
      <b-badge
        class="place"
        pill
        variant="secondary"
        :key="post.id + '-' + place.id"
        v-for="place in post.places"
        >{{ place.name }}</b-badge
      >
    </template>
  </b-card>
</template>

<script>
import moment from "moment";

export default {
  name: "Post",
  props: {
    post: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    handle_read_more(post_id) {
      this.$router
        .push({
          name: "Post",
          params: { id: post_id }
        })
        .catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            console.error(err);
          }
        });
    },

    format_date(date) {
      return moment.utc(date).format("DD/MM/YYYY");
    }
  }
};
</script>

<style scoped>
.card {
  margin: 10px;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s;
}
.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.place {
  margin-left: 5px;
}
.text-body {
  text-align: justify;
}
.date {
  font-size: 0.9rem;
  color: #999;
}
</style>

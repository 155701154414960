import { Post } from "../../models/Post";
import PostUtils from "../../utils/postUtils";

const store = {
  state: () => ({
    posts: [],
    current_post: null
  }),
  mutations: {
    update_posts(state: any, posts: Post[]) {
      state.posts = posts;
    },
    update_current_post(state: any, post: Post) {
      state.current_post = post;
    }
  },
  actions: {
    async fetch_all_posts(context: any) {
      const postUtils = new PostUtils();
      await postUtils.fetchAllPosts(1).then((posts: Post[]) => {
        context.commit("update_posts", posts);
      });
    },
    async fetch_post(context: any, id: number) {
      const postUtils = new PostUtils();
      await postUtils.fetchPostById(id).then((post: Post) => {
        context.commit("update_current_post", post);
      });
    },
    async fetch_drafts(context: any) {
      const postUtils = new PostUtils();
      await postUtils.fetchAllPosts(0).then((posts: Post[]) => {
        context.commit("update_posts", posts);
      });
    },
    async reset_current_post(context: any) {
      context.commit("update_current_post", null);
    },
    async create_post(context: any, post: Post) {
      const postUtils = new PostUtils();
      return postUtils.createPost(post).then((newPost: Post) => {
        context.commit("update_current_post", newPost);
        return newPost.id;
      });
    },
    async update_post(context: any, post: Post) {
      const postUtils = new PostUtils();
      await postUtils.updatePost(post).then((newPost: Post) => {
        context.commit("update_current_post", newPost);
      });
    },
    async delete_post(context: any) {
      const postUtils = new PostUtils();
      await postUtils.deletePost(context.state.current_post.id);
    },
    async publish_comment(context: any, comment: string) {
      const postUtils = new PostUtils();
      await postUtils
        .publishComment(context.state.current_post.id, comment)
        .then((post: Post) => {
          context.commit("update_current_post", post);
        });
    },

    async delete_comment(context: any, comment_id: number) {
      const postUtils = new PostUtils();
      await postUtils
        .deleteComment(context.state.current_post.id, comment_id)
        .then((post: Post) => {
          context.commit("update_current_post", post);
        });
    }
  },
  getters: {
    getPosts: (state: any) => state.posts,
    getCurrentPost: (state: any) => state.current_post
  }
};

export default store;

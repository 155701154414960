import Vue from "vue";
import Vuex from "vuex";
import UserStore from "./modules/user";
import MapStore from "./modules/map";
import PostStore from "./modules/post";

Vue.use(Vuex);

const stores = {
  user: UserStore,
  map: MapStore,
  post: PostStore
};

const modules = Object.entries(stores).reduce(
  (_modules: any, [name, store]) => {
    _modules[name] = {
      namespaced: true,
      ...store
    };
    return _modules;
  },
  {}
);

const $store = new Vuex.Store({
  modules,
  strict: true
});

export default $store;

<template>
  <div class="container mb-5" v-if="user">
    <h3>Voyages</h3>
    <b-button
      class="mb-3"
      v-if="user.role === 1"
      size="sm"
      pill
      variant="success"
      @click="$router.push({ name: 'NewPlace' })"
      ><font-awesome-icon icon="plus" /> Ajouter un endroit</b-button
    >
    <TravelDashboard />
    <div class="card mt-3 mb-5">
      <div class="card-body">
        <div class="search-container">
          <b-input
            v-model="text_search"
            placeholder="Rechercher des endroits"
          ></b-input>
        </div>
        <div class="places-container">
          <b-list-group flush class="mt-5">
            <b-list-group-item
              href="#"
              v-for="place in filtered_places"
              :key="place.id"
              class="flex-column align-items-start"
            >
              <div class="item-container">
                <div class="check-container" @click="handle_check(place)">
                  <font-awesome-icon
                    class="uncheck"
                    title="Pas encore visité !"
                    v-if="!place.done"
                    icon="circle"
                  />
                  <transition name="bounce" mode="out-in">
                    <font-awesome-icon
                      class="check"
                      title="Déjà visité !"
                      v-if="place.done"
                      icon="check-circle"
                    />
                  </transition>
                </div>
                <div
                  class="place-container"
                  @click="
                    $router.push({ name: 'Place', params: { id: place.id } })
                  "
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 :title="place.country.name_fr" class="mb-1">
                      <span
                        :class="
                          'flag-icon flag-icon-' +
                            place.country.code.toLowerCase()
                        "
                      ></span>
                      {{ place.name }}
                    </h5>

                    <div class="align-self-center d-flex align-content-center">
                      <b-badge variant="primary" v-if="place.posts.length" pill
                        >{{ place.posts.length.toString() }}
                        {{
                          place.posts.length > 1 ? "articles" : "article"
                        }}</b-badge
                      >
                    </div>
                  </div>

                  <p class="mb-1">
                    {{ place.description }}
                  </p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
    <b-modal id="modal-check-date" title="Date de validation">
      <div v-if="selected_place">
        <h5>
          <span
            :class="
              'flag-icon flag-icon-' + selected_place.country.code.toLowerCase()
            "
          ></span>
          {{ selected_place.name }}
        </h5>
        <label class="mt-3" for="date-check">Visité le</label>
        <b-form-datepicker
          placeholder="Pas de date sélectionnée"
          id="date-check"
          locale="fr-FR"
          start-weekday="1"
          label-help=""
          reset-button
          label-reset-button="Réinitialiser"
          v-model="date_check"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-check-date')"
          >Annuler</b-button
        >
        <b-button variant="primary" @click="toggle_done">Valider</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TravelDashboard from "@/components/TravelDashboard";
export default {
  name: "Travels",
  components: { TravelDashboard },
  data() {
    return { text_search: "", selected_place: null, date_check: null };
  },
  computed: {
    ...mapGetters({ user: "user/getUser", places: "map/getPlaces" }),
    filtered_places() {
      if (!this.places.length) {
        return [];
      }
      return this.places.filter(place => {
        const text = this.text_search.toLowerCase();
        return (
          place.name.toLowerCase().includes(text) ||
          place.description.toLowerCase().includes(text) ||
          place.country.name_fr.toLowerCase().includes(text) ||
          place.country.code.toLowerCase().includes(text)
        );
      });
    }
  },
  methods: {
    ...mapActions({
      fetch_places: "map/fetch_places",
      update_place: "map/update_place",
      toggle_done: "map/toggle_done"
    }),
    handle_check(place) {
      this.selected_place = place;
      if (!place.done) this.$bvModal.show("modal-check-date");
      else this.toggle_done();
    },
    toggle_done() {
      if (this.user.role === 1) {
        this.update_place({
          ...this.selected_place,
          country_id: this.selected_place.country.id,
          done: !this.selected_place.done,
          date_done: this.selected_place.done ? null : this.date_check
        })
          .then(() => {
            this.fetch_places();
            this.$bvModal.hide("modal-check-date");
          })
          .catch(err => {
            console.error(err);
            this.error = JSON.parse(err.message);
          });
      }
    }
  },
  mounted() {
    this.fetch_places();
  }
};
</script>

<style scoped>
.card-body {
  text-align: left;
}
.search-container {
  max-width: 400px;
  margin: 0 auto;
}
.places-container {
  max-height: 65vh;
  overflow-y: scroll;
}
.flag-icon {
  width: 1.5rem;
  vertical-align: top;
}
.item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.place-container {
  width: calc(100% - 50px);
  display: inline-block;
}
.check-container {
  width: 50px;
}
.check {
  color: green;
  font-size: 30px;
  opacity: 0.7;
  position: absolute;
  top: calc(50% - 15px);
}
.uncheck {
  color: #c2c2c2;
  font-size: 30px;
  opacity: 0.7;
  position: absolute;
  top: calc(50% - 15px);
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<template>
  <div class="container mb-5" v-if="place">
    <b-button href="#" variant="link" @click="$router.push({ name: 'Travels' })"
      ><font-awesome-icon icon="angle-left" /> Retour</b-button
    >

    <div class="card mb-3">
      <div class="card-body">
        <b-button
          v-if="user.role === 1"
          class="edit-button"
          variant="danger"
          size="sm"
          pill
          v-b-modal.modal-delete
          ><font-awesome-icon icon="trash"
        /></b-button>
        <b-button
          v-if="user.role === 1"
          class="edit-button"
          variant="light"
          size="sm"
          pill
          @click="handle_edit"
          ><font-awesome-icon icon="pencil-alt"
        /></b-button>

        <h4>
          <span
            :class="'flag-icon flag-icon-' + place.country.code.toLowerCase()"
          ></span>
          {{ place.name }}, {{ place.country.name_fr }}
        </h4>
        {{ place.description }}
        <hr />

        <div class="mt-2" v-if="place.done">
          <font-awesome-icon style="color: green;" icon="check-circle" /> Validé
          le {{ format_date(place.date_done) }}
        </div>

        <div class="mt-5" v-if="place.posts.length">
          <h4>Articles associés :</h4>
          <div class="posts-container">
            <Post v-for="post in place.posts" :key="post.id" :post="post" />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-delete" title="Supprimer ?">
      <p class="my-4">
        Es-tu sûr de vouloir supprimer cet endroit ? L'opération n'est pas
        réversible !
      </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">
          Oups !
        </b-button>
        <b-button variant="danger" @click="handle_delete">
          Oui supprimer
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Post from "@/components/Post";
import moment from "moment";

export default {
  name: "Place",
  components: { Post },
  computed: {
    ...mapGetters({ place: "map/getCurrentPlace", user: "user/getUser" })
  },
  methods: {
    ...mapActions({
      fetch_place: "map/fetch_place",
      delete_place: "map/delete_place"
    }),
    handle_edit() {
      this.$router.push(`/places/${this.$route.params.id}/edit`);
    },
    handle_delete() {
      this.delete_place().then(() => {
        this.$router.push({ name: "Travels" });
      });
    },
    format_date(date, type) {
      moment.locale("fr");
      return moment.utc(date).format("dddd Do MMMM YYYY");
    }
  },
  mounted() {
    this.fetch_place(this.$route.params.id);
  }
};
</script>

<style scoped>
.container {
  text-align: left;
}
.edit-button {
  float: right;
}
.flag-icon {
  width: 30px;
  vertical-align: top;
}
.posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>

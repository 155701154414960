<template>
  <div class="container">
    <h3>Erreur 404 - La page demandée n'existe pas !</h3>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>

<style scoped>
.container {
  margin-top: 100px;
}
</style>

import { User } from "../../models/User";
import UserUtils from "../../utils/userUtils";

const store = {
  state: () => ({
    user: null,
    all_users: []
  }),
  mutations: {
    update_all_users(state: any, users: User[]) {
      state.all_users = users;
    },
    update_user(state: any, user: User) {
      state.user = user;
    }
  },
  actions: {
    async fetch_all_users(context: any) {
      const userUtils = new UserUtils();
      await userUtils.fetchAllUsers().then((users: User[]) => {
        context.commit("update_all_users", users);
      });
    },
    async fetch_user(context: any) {
      const userUtils = new UserUtils();
      await userUtils.fetchUser().then((user: User) => {
        context.commit("update_user", user);
      });
    },
    async create_user(context: any, newUser: any) {
      const userUtils = new UserUtils();
      await userUtils.createUser(newUser);
    },
    async update_profile(context: any, newUser: any) {
      const userUtils = new UserUtils();
      await userUtils.updateProfile(newUser).then((user: User) => {
        context.commit("update_user", user);
      });
    },
    async update_password(context: any, { old_password, new_password }: any) {
      const userUtils = new UserUtils();
      await userUtils.updatePassword(
        context.state.user.id,
        old_password,
        new_password
      );
    },
    async delete_user(context: any, user_id: number) {
      const userUtils = new UserUtils();
      await userUtils.deleteUser(user_id);
    }
  },
  getters: {
    getUser: (state: any) => state.user,
    getAllUsers: (state: any) => state.all_users
  }
};

export default store;

<template>
  <div class="card">
    <div class="card-header">Commentaires</div>
    <div class="card-body">
      <div class="add-comment-section">
        <h5>Ajouter un commentaire :</h5>
        <b-form-textarea
          id="textarea"
          v-model="comment"
          placeholder="Réagis à la publication (maximum 300 caractères)..."
          :state="remain_char >= 0 ? null : false"
          rows="3"
        ></b-form-textarea>
        <div class="remain-char">{{ remain_char }} caractères restants</div>
        <br />
        <b-button
          class="publish-button"
          variant="primary"
          @click="handle_publish_comment"
          :disabled="!comment.length || remain_char < 0"
          >Publier</b-button
        >
      </div>
      <div class="comments-section mt-5">
        <h5>Réactions des utilisateurs :</h5>
        <i v-if="!post.comments.length">Pas de commentaires</i>
        <transition-group name="fade">
          <div
            class="comment row mt-3"
            :key="comment.id"
            v-for="comment in post.comments"
          >
            <div class="avatar-img">
              <b-avatar
                variant="secondary"
                size="lg"
                :src="'/ressources/pictures/' + comment.user.profile_picture"
                :text="
                  comment.user.firstname.charAt(0) +
                    comment.user.lastname.charAt(0)
                "
              ></b-avatar>
            </div>
            <div class="comment-wrapper">
              <div class="comment-author">
                {{ comment.user.firstname }} {{ comment.user.lastname }}
                <span class="comment-date"
                  >• il y a {{ time_since(comment.createdAt) }}</span
                >
              </div>
              <div class="comment-text">
                {{ comment.text }}
              </div>
              <div
                class="comment-action"
                v-if="comment.user.id === user.id || user.role === 1"
              >
                <!-- TODO: Edit comment-->

                <b-button
                  variant="link sm"
                  style="color: #cb0000; font-size: .7rem;"
                  class="delete-comment"
                  @click="handle_delete_comment(comment.id, $event)"
                  >Supprimer</b-button
                >
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PostComments",
  data() {
    return {
      comment: "",
      error: ""
    };
  },
  computed: {
    ...mapGetters({ post: "post/getCurrentPost", user: "user/getUser" }),
    remain_char() {
      return 300 - this.comment.length;
    }
  },
  methods: {
    ...mapActions({
      publish_comment: "post/publish_comment",
      delete_comment: "post/delete_comment"
    }),
    handle_publish_comment() {
      if (this.comment.length && this.remain_char >= 0) {
        this.publish_comment(this.comment)
          .then(() => {
            this.comment = "";
          })
          .catch(err => {
            console.error(err);
            this.error = err;
          });
      }
    },
    handle_delete_comment(id, e) {
      e.stopPropagation();
      this.delete_comment(id);
    },
    time_since(date) {
      const parsed_date = new Date(Date.parse(date));
      const seconds = Math.floor((new Date() - parsed_date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " ans";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " mois";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " jours";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " heures";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " secondes";
    }
  }
};
</script>

<style scoped>
.remain-char {
  color: #999;
  float: right;
  font-size: 0.8rem;
}
.publish-button {
  float: right;
}
.comments-section {
  font-size: 0.8rem;
}
.avatar-img {
  width: 56px;
}
.comment-wrapper {
  grid-auto-rows: minmax(0, auto);
  max-width: 90%;
}
.comment-action {
  font-size: 0.6rem;
  margin-left: 20px;
}
.comment-author {
  color: #105e82;
  font-weight: bold;
  vertical-align: top;
  margin-left: 10px;
  line-height: 13px;
}
.comment-date {
  color: #999;
}
.comment-text {
  background: white none repeat scroll 0 0;
  padding: 8px 10px;
  border-radius: 18px;
  border: 1px solid rgba(204, 212, 216, 0.8);
  margin-left: 10px;
  max-width: 92%;
  display: inline-block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

import { render, staticRenderFns } from "./PostPlaces.vue?vue&type=template&id=148a0660&scoped=true&"
import script from "./PostPlaces.vue?vue&type=script&lang=js&"
export * from "./PostPlaces.vue?vue&type=script&lang=js&"
import style0 from "./PostPlaces.vue?vue&type=style&index=0&id=148a0660&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148a0660",
  null
  
)

export default component.exports